import React from 'react';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import Head from '../../components/head';
import Layout from '../../components/layout';
import AddOnList from '../../components/addonList';

const sanityConfig = {
  projectId: '80jisvar',
  dataset: 'production',
};

const serializers = {
  types: {
    imgBlock: ({ node }) => {
      const gatsbyImageData = getGatsbyImageData(node.asset._id, {}, sanityConfig);
      return <GatsbyImage image={gatsbyImageData} alt="" placeholder="dominantColor" className="mb-6" />;
    },
  },
};

export default function SinglePage({ data: { page, allAddOns } }) {
  const addons = allAddOns.nodes;
  return (
    <Layout>
      <Head title={page.headline} />
      <div className="max-w-screen-lg m-auto sm:animate-fade-content-page">
        <h1>
          <span aria-hidden="true" />
          {page.headline}
        </h1>
        <SanityBlockContent
          renderContainerOnSingleChild
          blocks={page._rawContent}
          serializers={serializers}
          className="sm:text-lg mb-8 sm:mb-20"
        />
        <AddOnList addons={addons} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    page: sanityPage(id: { eq: "-27ad4578-2d9d-5cdd-adb5-75d9b77f1a61" }) {
      _rawContent(resolveReferences: { maxDepth: 10 })
      headline
    }
    allAddOns: allSanityAddOn {
      nodes {
        name
        text
        image {
          asset {
            gatsbyImageData(fit: CROP, aspectRatio: 1.5, placeholder: DOMINANT_COLOR)
          }
        }
        slug {
          current
        }
      }
    }
  }
`;
